import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/Layout'

const NotFoundPage = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="template" lang="ja" />
        <title>{`404 Not Found | ${title}`}</title>
      </Helmet>
      <div className="common-header clearfix">
        <h1>404 Not Found</h1>
        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /<li>404 Not Found</li>
        </div>
      </div>

      <div className="main-wrapper">
        <div className="row">
          <div className="container">
            お探しのページは一時的にアクセスができない状況にあるか、移動もしくは削除された可能性があります。
            <br />
            また、URL、ファイル名にタイプミスがないか再度ご確認ください。
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
